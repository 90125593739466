<template lang="html" src="./planDowngrade.html" />
  
<script>
import mixins from "@/mixins/mixins";
export default {
  mixins: [mixins],
  data() {
    return {
      addons: [],
      plan: [],
      customer: null,
      clickedReasons: [],
      keepButtonText: "Quero manter",
      cancelButtonText: "Quero cancelar",
      reasonsCancellation: [
        { id: 1, name: "Motivos financeiros" },
        { id: 2, name: "Descobri um serviço melhor" },
        { id: 3, name: "Não usou o serviço com frequência" },
        { id: 4, name: "Outros" },
        { id: 5, name: "Prefiro não responder" },
      ],
    }
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted() {
    const vm = this;
    vm.getServices();
    vm.getCustomerPlan();
  },
  methods: {
    getCustomerPlan(){
      const vm = this; 
      vm.customer = vm.$session.get("customerData");
      vm.plan = vm.customer.customerPlan; 

    },
    getServices() {
      const vm = this;
      const patientId = vm.$session.get("customerData").patientId;
      vm.axios
        .get(`${vm.$store.state.filooServer}patient/services/${patientId}`)
        .then(function (response) {
          if (response.status === 200) {
            vm.fillAddonsList(response.data);
            
          }
        })
        .catch(function (error) {
          console.error({
            error: error,
            endpoint: `${vm.$store.state.filooServer}new-integra/plans`,
            method: "getServices",
            http_method: "GET",
          });
        });
    },
    goToProductsPage(id){
      const vm = this;
      const total = vm.addons.reduce((a, b) => +a + +b.value, 0);
      const planAndAddOnsData = {
        plan: vm.plan,
        addons: vm.addons,
        total: parseFloat(total),
        knowMoreAddonId: id,
      };
      vm.$router.push({
        name: "products-know-more",
        params: {
          planAndAddOnsData: planAndAddOnsData,
        },
      });
      localStorage.setItem("planAndAddOnsData", JSON.stringify(planAndAddOnsData));
    },
    downgrade() {
      const vm = this;
      const textError =
        "Não foi possível realizar o downgrade do seu plano";
      const dataCustomer = {
        name: vm.customer.name,
        email: vm.customer.email,
        cpf: vm.customer.cpf.replace(/\D+/g, ""),
        token: null,
        phone:
          vm.testNullUndefinedEmpty(vm.customer.phone)
            ? null
            : vm.customer.phone.replace(/\D+/g, ""),
        plan: vm.customer.customerPlan,
        additionalLife: vm.testNullUndefinedEmpty(vm.customer.customerPlan)
          ? 0
          : vm.customer.customerPlan.additionalLife,
      };

      vm.axios
        .post(
          vm.$store.state.filooServer + "to define",
          dataCustomer
        )
        .then(function (response) {
          if (response.status === 200) {
            console.log(response.data);
          }
        })
        .catch(function (error) {
          vm.showNotifyError(textError, 6000);
          console.error({
            method: "downgrade",
            "sent-object": dataCustomer,
            "http-method": "POST",
            error: error,
          });
        });
    },
    fillAddonsList(data){
      const vm = this;
      const acquiredPlanServices = data.acquiredPlanServices;
      if(!vm.testNullUndefinedEmpty(acquiredPlanServices)){
        const onlyPaidServices = acquiredPlanServices.filter(a => a.value > 0);
        vm.addons = onlyPaidServices.sort(function(a,b) {
          return a.value > b.value ? -1 : a.value < b.value ? 1 : 0;
        });
        for(const a in vm.addons){
          if(!vm.testNullUndefinedEmpty(vm.addons[a].name)){
            if(vm.addons[a].name.includes("seguros")){
              vm.addons[a].name= vm.addons[a].name.substring(0, vm.addons[a].name.indexOf(" ("));
            }
          }    
        }
      }    
    },
    handleResize() {
      const vm = this;
      vm.windowWidth = document.documentElement.clientWidth;
      if (vm.windowWidth < 370) {
        vm.webScreen = false;
        vm.mobileScreen = true;
        vm.keepButtonText = "Manter";
        vm.cancelButtonText = "Cancelar";
      } else {
        vm.webScreen = true;
        vm.mobileScreen = false;
        vm.keepButtonText = "Quero manter";
        vm.cancelButtonText = "Quero cancelar";
      }
    },
  },
}
</script>

<style lang="scss" src="./planDowngrade.scss" />
